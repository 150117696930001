import CodeHelpers from "../common/CodeHelpers";
import { AuthenticationType } from "../../models/ProviderDetails/AuthenticationType";
import BasicAuthConfig from "../../models/ProviderDetails/BasicAuthConfig";
import NameValueWithTypeAndPositionInput from "../../models/SSOConfig/NameValueWithTypeAndPositionInput";
import Oauth2Config from "../../models/ProviderDetails/Oauth2Config";
import OutboundSAMLQueryStringParameterInput from "../../models/SSOConfig/OutboundSAMLQueryStringParameterInput";
import ProviderDetailsErrorModel from "../../models/ProviderDetails/ProviderDetailsErrorModel";
import ProviderDetailsExpansion from "../../models/ProviderDetails/ProviderDetailsExpansion";
import ProviderModel from "../../models/ProviderDetails/ProviderModel";
import { ProviderOfferingType } from "../../models/ProviderDetails/ProviderOfferingType";
import KeyValueWithTypeAndPositionErrorModel from "../../models/SSOConfig/KeyValueWithTypeAndPositionErrorModel";
import SSOConfigErrorModel from "../../models/SSOConfig/SSOConfigErrorModel";
import SSOConfigModel from "../../models/SSOConfig/SSOConfigModel";
import TestingFrameworkItemModel from "../../models/ProviderFramework/TestingFrameworkItemModel";
import { FrameworkModes } from "../../models/ProviderFramework/FrameworkMode";
import ProviderDetailsConstants from "../../constants/providerDetailsConstants";
import SSOConfigConstants from "../../constants/ssoConfigConstants";
import OrganizationDetailsErrorModel from "../../models/Organizations/OrganizationErrorModel";
import OrganizationModel, { EnvironmentType, IntegrationType } from "../../models/Organizations/OrganizationModel";

class OrganizationAiccUrlExistModel{
    constructor(
        public Id?: string| undefined,
        public AiccUrl?: string[] | undefined,
        public TenantIdentifier?: string | undefined
    ){}
}

export default class FrameworkValidationService {
    public static ProviderDetailsValidate = (provider: ProviderModel | null | undefined, providerDetailsExpansion: ProviderDetailsExpansion | null | undefined, basicAuthConfig: BasicAuthConfig | null | undefined, oauth2Config: Oauth2Config | null | undefined, providers: ProviderModel[] | undefined, frameworkMode: FrameworkModes): ProviderDetailsErrorModel | null => {
        var providerDetailsErrorMessages = new ProviderDetailsErrorModel();
        var isProviderDetailsHasErrors = false;
        if(CodeHelpers.IsStringNullOrEmpty(provider?.Name)) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.nameErrorMessage = "Please, enter provider name";
        } else if(providers && frameworkMode === FrameworkModes.Create){
            var isNameAlreadyUsed = false;
            for(var i = 0; i< providers.length; i++) {
                if(providers[i].Name === provider?.Name) {
                    isNameAlreadyUsed = true;
                    break;
                } 
            }
            if(isNameAlreadyUsed) {
                isProviderDetailsHasErrors = true;
                providerDetailsErrorMessages.nameErrorMessage = "Please, enter unique name for the provider";
            }
        }
        if(CodeHelpers.IsStringNullOrEmpty(provider?.Abbreviation)) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.abbreviationErrorMessage = "Please, enter abbreviation for global content ID";
        } else if(providers && frameworkMode === FrameworkModes.Create){
            var isAbbreviationAlreadyUsed = false;
            for(var j = 0; j< providers.length; j++) {
                if(providers[j].Abbreviation === provider?.Abbreviation) {
                    isAbbreviationAlreadyUsed = true;
                    break;
                } 
            }
            if(isAbbreviationAlreadyUsed) {
                isProviderDetailsHasErrors = true;
                providerDetailsErrorMessages.abbreviationErrorMessage = "Please, enter unique abbreviation for the provider";
            }
        }
        if(CodeHelpers.IsStringNullOrEmpty(provider?.CourseSyncUrl)) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.SyncURLErrorMessage = "Please, enter course sync URL";
        }
        if(!CodeHelpers.IsStringNullOrEmpty(providerDetailsExpansion!.ProviderDescription) && providerDetailsExpansion!.ProviderDescription.length > ProviderDetailsConstants.DESCRIPTION_MAX_LETTERS_COUNT) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.ProviderNotesErrorMessage = "Please reduce text of provider notes or leave this field"
        }
        if(provider?.ProviderOfferingType == null) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.OfferingTypeErrorMessage = "Please, choose offering type";
        } 
        if(provider?.Type == null) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.TrainingTypeErrorMessage = "Please, choose training type";
        }
        
        if(!provider?.SyncTime || !provider?.SyncTime[0] ){
            if(provider)
                provider.SyncTime = "10:00:00";
        }
        if(provider?.ProviderOfferingType == ProviderOfferingType.Push){
            //N/A - no auth.
        }
        else if(provider?.AuthenticationType == null) {
            isProviderDetailsHasErrors = true;
            providerDetailsErrorMessages.AuthenticationTypeErrorMessage = "Please, choose authentication type";
        } else {
            if(provider?.AuthenticationType === AuthenticationType.oAuth2) {
                if(CodeHelpers.IsStringNullOrEmpty(oauth2Config!.AuthenticationUrl)) {
                    isProviderDetailsHasErrors = true;
                    providerDetailsErrorMessages.AuthenticationURLErrorMessage = "Please, enter authentication URL";
                }
                if(CodeHelpers.IsStringNullOrEmpty(oauth2Config!.ClientID)) {
                    isProviderDetailsHasErrors = true;
                    providerDetailsErrorMessages.ClientIDErrorMessage = "Please, enter client ID";
                }
                if(CodeHelpers.IsStringNullOrEmpty(oauth2Config!.ClientSecretKey)) {
                    isProviderDetailsHasErrors = true;
                    providerDetailsErrorMessages.ClientSecretKeyErrorMessage = "Please, enter client secret key";
                }
            }
            if(provider.AuthenticationType === AuthenticationType.Basic) {
                if(CodeHelpers.IsStringNullOrEmpty(basicAuthConfig!.Username)) {
                    isProviderDetailsHasErrors = true;
                    providerDetailsErrorMessages.UsernameErrorMessage = "Please, enter username";
                }
                if(CodeHelpers.IsStringNullOrEmpty(basicAuthConfig!.Password)) {
                    isProviderDetailsHasErrors = true;
                    providerDetailsErrorMessages.PasswordErrorMessage = "Please, enter password";
                }
            }
        }
        if(isProviderDetailsHasErrors) {
            return providerDetailsErrorMessages;
        }
        else {
            return null;
        }
    }

    public static TestSubscriptionValidate = (testSubscriptionConfig: {[id: number] : TestingFrameworkItemModel} | undefined): { [id: number]: string } | null  => {
        var testSubscriptionErrorMessages: {[id: number]: string} = {};
        var isTestSubscriptionHasErrors = false;
        if(testSubscriptionConfig) {
            var testSubscrValues = Object.values(testSubscriptionConfig);
            
            for(var i = 0; i < testSubscrValues.length; i++){
                var testSubscription = testSubscriptionConfig[i];
                if(CodeHelpers.IsStringNullOrEmpty(testSubscription.CIID) && testSubscription.CIIDShow && testSubscription.isChecked) {
                    isTestSubscriptionHasErrors = true;                
                    testSubscriptionErrorMessages[i] = "Please enter the CIID";
                }
            }
            if(isTestSubscriptionHasErrors) {
                return testSubscriptionErrorMessages;
            }
        }
        return null;
    }

    public static AdditionalSettingsValidate = (additionalSettings: NameValueWithTypeAndPositionInput[]):  { [id: number]: KeyValueWithTypeAndPositionErrorModel } | null => {
        var additionalSettingsErrorMessages: {[id: number]: KeyValueWithTypeAndPositionErrorModel} = {};
        var isAdditionalSettingsHasErrors = false;
        if(additionalSettings) {
            for(var i = 0; i < additionalSettings.length; i++) {
                var additionalSetting = additionalSettings[i];
                var additionalSettingError = new KeyValueWithTypeAndPositionErrorModel();

                if(CodeHelpers.IsStringNullOrEmpty(additionalSetting.name)) {
                    additionalSettingError.name = "Please, enter name";
                }
                if(additionalSetting.type == null) {
                    additionalSettingError.type = "Please, enter type";
                }
                if(CodeHelpers.IsStringNullOrEmpty(additionalSetting.value)) {
                    additionalSettingError.value = "Please, enter value";
                }

                var errorMessages = Object.values(additionalSettingError);
                var errorMessagesCount = 0;
                // eslint-disable-next-line
                errorMessages.forEach(item => {
                    if(!CodeHelpers.IsStringNullOrEmpty(item)) {
                        errorMessagesCount++;
                    }
                })
                if(errorMessagesCount !== 0 && errorMessagesCount !== errorMessages.length) {
                    isAdditionalSettingsHasErrors = true;
                    additionalSettingsErrorMessages[i] = additionalSettingError;
                }
            }

            if(isAdditionalSettingsHasErrors) {
                return additionalSettingsErrorMessages;
            }
        }
        return null;
    }

    public static QuerystringParametersValidate = (querystringParameters: OutboundSAMLQueryStringParameterInput[]):  {[id: number]: OutboundSAMLQueryStringParameterInput} | null => {
        var errorMessages: {[id: number]: OutboundSAMLQueryStringParameterInput} = {};
        var isHasErrors = false;
        if(querystringParameters) {
            for(var i = 0; i < querystringParameters.length; i++) {
                var querystringParameter = querystringParameters[i];
                var additionalSettingError = new OutboundSAMLQueryStringParameterInput();

                if(CodeHelpers.IsStringNullOrEmpty(querystringParameter.QueryStringParameterName)) {
                    additionalSettingError.QueryStringParameterName = "Please, enter parameter name";
                }
                if(CodeHelpers.IsStringNullOrEmpty(querystringParameter.SamlAttributeName)) {
                    additionalSettingError.SamlAttributeName = "Please, enter attribute value";
                }

                var messages = Object.values(additionalSettingError);
                var errorMessagesCount = 0;
                // eslint-disable-next-line
                messages.forEach(item => {
                    if(!CodeHelpers.IsStringNullOrEmpty(item)) {
                        errorMessagesCount++;
                    }
                })
                if(errorMessagesCount !== 0 && errorMessagesCount !== messages.length) {
                    isHasErrors = true;
                    errorMessages[i] = additionalSettingError;
                }
            }

            if(isHasErrors) {
                return errorMessages;
            }
        }
        return null;
    }

    public static SSOConfigurationValidatate = (ssoConfigModel: SSOConfigModel | null | undefined): SSOConfigErrorModel | null => {
        var ssoConfigErrorMessages = new SSOConfigErrorModel();
        var isSSOConfigHasErrors = false;
        if(ssoConfigModel!.EnableSSO) {
            if(!ssoConfigModel!.SSOIDOUID) {
                isSSOConfigHasErrors = true;
                ssoConfigErrorMessages.SSOIDOUIDErrorMessage = "Please, enter SSO ID (UI ID)";
            }
            if(CodeHelpers.IsStringNullOrEmpty(ssoConfigModel!.ACSURL)) {
                isSSOConfigHasErrors = true;
                ssoConfigErrorMessages.ACSURLErrorMessage = "Please, enter ACS URL";
            }
            if(ssoConfigModel!.TimestampFormat == null) {
                isSSOConfigHasErrors = true;
                ssoConfigErrorMessages.TimestampErrorMessage = "Please, enter Timestamp Format";
            }
            if(!CodeHelpers.IsStringNullOrEmpty(ssoConfigModel!.Issuer) && !SSOConfigConstants.ISSUER_REGEX.test(ssoConfigModel?.Issuer!)) {
                isSSOConfigHasErrors = true;
            }
            if(ssoConfigModel?.IsSpInitiatedEnabled && CodeHelpers.IsStringNullOrEmpty(ssoConfigModel.AuthenticationRequestIssuer)) {
                isSSOConfigHasErrors = true;
                ssoConfigErrorMessages.AuthenticationRequestIssuerErrorMessage = "Please, enter authentication request issuer";
            }

            ssoConfigErrorMessages.AdditionalSettingsErrorMessages = FrameworkValidationService.AdditionalSettingsValidate(ssoConfigModel!.AdditionalSettings);
            ssoConfigErrorMessages.QuerystringParametersErrorMessages = FrameworkValidationService.QuerystringParametersValidate(ssoConfigModel!.QuerystringParameters);

            if(ssoConfigErrorMessages.AdditionalSettingsErrorMessages != null || ssoConfigErrorMessages.QuerystringParametersErrorMessages != null) {
                isSSOConfigHasErrors = true;
            }
        }
        if(isSSOConfigHasErrors) {
            return ssoConfigErrorMessages;
        }
        else {
            return null;
        }
    }

    public static OrganizationDataValidate = (organization: OrganizationModel | null | undefined, organizations: OrganizationModel[], frameworkMode: FrameworkModes): OrganizationDetailsErrorModel | null => {
        var orgErrorMessages = new OrganizationDetailsErrorModel();
        var isOrgDetailError = false;

        if(CodeHelpers.IsStringNullOrEmpty(organization?.Name)) {
            isOrgDetailError = true;
            orgErrorMessages.nameErrorMessage = "Please enter an organization name";
        }
        organizations.forEach(element => {
            if(element.AiccUrl){
                element!.AiccUrl = element!.AiccUrl.map(url => {
                    if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    return 'https://' + url;
                    }
                    return url;
                })
            }
          });
        var organizationToChecks = organizations.map(x=> new OrganizationAiccUrlExistModel(x.Id, x.AiccUrl?.map(x=> new URL(x).host), x.TenantIdentifier))
        organizationToChecks.forEach(element => {
            if(((!element.TenantIdentifier && !organization?.TenantIdentifier) || (element.TenantIdentifier == organization?.TenantIdentifier)) 
            && element.AiccUrl?.some(item => organization?.AiccUrl?.map(x=>new URL(x).host)?.includes(item))
            && organization?.Id != element.Id ){
                isOrgDetailError = true;
                orgErrorMessages.aiccUrlErrorMessage = "Aicc URL and Identifier pairing already exist";    
            }
        });
        
        let hasDuplicates = false;
        const seenStrings = {};
        if(organization?.OrgSettingCollection?.length > 1){
            for (const str of organization?.OrgSettingCollection?.map(x=>x.Key)) {
              if (seenStrings[str]) {
                hasDuplicates = true;
                break;
              } else {
                seenStrings[str] = true;
              }
            }

            if(hasDuplicates){
                orgErrorMessages.organizationCorpErrorMessage = "Corp Settings cannot include duplicated values";    
                isOrgDetailError = true;
            }
        }
        if(organization?.Environment == EnvironmentType.Production && !organization?.SalesforceId){
            orgErrorMessages.salesForceIdErrorMessage = "Please enter sales force id for production";
            isOrgDetailError = true;
        }
        if(organization?.LearningPlatform?.toLocaleLowerCase() == "csx" || organization?.LearningPlatform?.toLocaleLowerCase() == "sbx" )
        {
            isOrgDetailError = true;
            orgErrorMessages.learningPlatformErrorMessage = "CSX or SBX Learning platforms not allowed";       
        }
        if((organization?.LearningPlatform == "UKG" ||organization?.LearningPlatform == "ScormCloud" || organization?.LearningPlatform == "Workday" ) &&
            organization?.IntegrationType != IntegrationType.Deeplink && !organization?.TenantIdentifier ){
                isOrgDetailError = true;
                orgErrorMessages.tenantIdentifierErrorMessage = "Tenant Identifier required for specific learning platforms";       
        }
        if(!organization?.LearningPlatform || organization?.LearningPlatform?.length < 1 ) 
        {
            isOrgDetailError = true;
            orgErrorMessages.learningPlatformErrorMessage = "Learning platform is required";       
        }
        if(organization?.IntegrationType != undefined && organization?.IntegrationType != IntegrationType.Deeplink && (!organization?.AiccUrl || organization?.AiccUrl?.length == 0)) {
            isOrgDetailError = true;
            orgErrorMessages.aiccUrlErrorMessage = "Please enter AICC uris";
        } 
        if(!organization?.OrgBundle || organization?.OrgBundle?.length < 1 ) {
            isOrgDetailError = true;
            orgErrorMessages.ccaBundleErrorMessage = "Please select cca bundles";
        } 
        if(organization?.IntegrationType == undefined){
            isOrgDetailError = true;
            orgErrorMessages.integrationTypeErrorMessage = "Please select an integration type";
        }
        if(organization?.Environment == undefined){
            isOrgDetailError = true;
            orgErrorMessages.envTypeErrorMessage = "Please select an environment type";
        } 
        if(organization?.IntegrationType != undefined && organization.IntegrationType== IntegrationType.AICC_SNS && CodeHelpers.IsStringNullOrEmpty(organization.SubscriberUri)){
            isOrgDetailError = true;
            orgErrorMessages.subscriberUriErrorMessage = "Please enter a valid subscription uri";
        }
        if(organization?.IntegrationType != undefined && organization?.IntegrationType == IntegrationType.Deeplink && (organization?.Sso?.Type != 1 && organization?.Sso?.Type != 0)) {
            isOrgDetailError = true;
            orgErrorMessages.ssoTypeErrorMessage = "Please enter a valid sso type";
        }
        if(isOrgDetailError) {
            return orgErrorMessages;
        }
        else {
            return null;
        }
    }

}